import React, { useCallback, useEffect, useRef, useState } from "react";
import ContentList from "../ContentList";
import ContentWrapper from "../ContentWrapper";
import {
  MusicAboutWrapper,
  MusicContentWrapper,
  MusicDetailsContainer,
  MusicDetailsWrapper,
  MusicHeaderWrapper,
  MusicHeading,
  MusicHeadingBgImage,
  MusicHeadingContainer,
  MusicHeadingImage,
  MusicHeadingImageWrapper,
  MusicHeadingSmall,
  MusicHeadingTextWrapper,
  MusicHeadingWrapper,
  MusicInfoWrapper,
  MusicLyricsWrapper,
  MusicNav,
  MusicNavLink,
  MusicOptionsContainer,
  MusicPageWrapper,
  MusicPlayerWrapper,
  MusicPlayPauseButton,
  MusicSubheading,
  Option,
  OptionButton,
  OptionMenuButton,
  OptionText,
  ProgressBar,
  ProgressBarWrapper,
  PurchaseButton,
  Select,
  SongDetailsText,
  SongDetailsWrapper,
  SubHeading,
  Text,
} from "./MusicPageElements";
import { FaPlay, FaPause, FaShare, FaRegMoneyBillAlt, FaCrown } from "react-icons/fa";
import { GiScrollUnfurled } from "react-icons/gi";
import { GoReport } from "react-icons/go";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { Slider } from "../Slider";
import Marquee from "react-text-marquee";
import { useAuth } from "../../contexts/AuthContext";
import { filterLikes, getArtistsNames, getSongTitle, stringFormatter, timestampToDate } from "../../utils";
import { useContext } from "react";
import { useAudio, usePreviewAudio } from "../AudioPlayer/context/AudioContext";
import { FcLikePlaceholder, FcLike } from "react-icons/fc";
import { AiOutlineEye, AiFillEye } from "react-icons/ai";
import { isUserPayingOut } from "../SonarMuseWebMonetization";
import { useNavigate, useLocation } from "react-router-dom";
import QRCodeForm from "../QRCodeForm";
import { useAlert } from "../Alert/AlertContext";

import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
  MUSIC_PLAYING_FROM,
  MUSIC_PURCHASE_OPTIONS,
  SLIDER_TYPES,
  TRANSACTION_OPTIONS,
} from "../../utils/Enum";
import Modal from "../Modal";
import PurchaseModal from "../PurchaseModal";
import { useModal } from "../Modal/ModalContext";
import MusicInfo from "./MusicInfo";
import {MusicOptionsModal, OpenMusicStorePlayer } from "../MenuOptions";
import useMusic from "../../hooks/useMusic";
import { NavContainer } from "../NavLink/NavLinkElements";
import MusicEditPage from "../MusicEditPage";
import OptionModal from "../OptionModal";
import { FormButton, FormInput1 } from "../CustomForm/CustomFormElements";
import { HoverOption } from "../HoverMenu/HoverMenuElements";
import CommentForm from "../CommentForm";
import CommentsSection from "../CommentsSection";
import useArtist from "../../hooks/useArtist";
import ScrollToTop from "../ScrollToTop";
import { shuffleSongs } from "../../utils/MusicUtils";
import { MdExplicit } from "react-icons/md";
import StreamingSubscriptionModal from "../StreamingSubscriptionModal";
import SongList, { MusicStoreSongList } from "../ContentList/SongList";
import { TextLink, TextRoute } from "../SignUp/SignUpElements";
import VideoPlayer from "../VideoPlayer";
import { VideoPlayerSkeleton } from "../Skeleton";
import { RiVideoFill } from "react-icons/ri";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";
import { getResizedImage } from "../../utils/FileUploadsUtils";
import MusicBanner from "../MusicBanner";
import DigitalPurchaseCard from "../Card/DigitalPurchaseCard";
import RoyaltyShareCard from "../Card/RoyaltyShareCard";
import useVideos from "../../hooks/useVideos";

function VideoPage({
  id,
  video,
  isSongPlaying,
  handleToggle,
  audioPlayer,
  stopMainPlayer,
  timeTravel,
  currentSong,
  playlist,
  currentTab,
  setTab,
  updateWebPageIcon
}) {
  const { currentUser, isUserSubscribed, getUserSubscription } = useAuth();
  const {goTo} = ScrollToTop()
  const { modalContent, toggleModal, isOpen, openModal, closeModal } = useModal();
  const [similarVideos, setSimilarVideos] = useState({});
  const [artistVideos, setArtistSongs] = useState({});
  const [collections, setCollections] = useState({})
  const navigate  = useNavigate();
  const { getSimilarVideos, getNFTokenCollectionsByVideo} = useVideos();
  const {searchArtistVideos} = useArtist({})
  const [isLoaded, setLoaded] = useState(false)
  const [isASLoaded, setASLoaded] = useState(false)
  const [isNFTLoaded, setNFTLoaded] = useState(false)
  const [isAlbumArtLoaded,setAlbumArtLoaded] = useState()
  const [albumArt, setAlbumArt] = useState(video?.albumArt)
  const { addAlert } = useAlert();

  const { setCurrentSong, setPlaylist, play, isQueueLoaded } = useAudio();
  const {currentPreview, setCurrentPreview,  setPreviewPlaylist, handlePreviewToggle, previewTimeTravel, togglePreviewPlay, isPreviewPlaying} = usePreviewAudio()
  
  
  const isPlayingFrom = useCallback(() => {
    if (
      localStorage?.getItem("isPlayingFrom") && 
      JSON.parse(localStorage?.getItem("isPlayingFrom"))?.playingFrom ===
        MUSIC_PLAYING_FROM.SONG &&
      JSON.parse(localStorage.getItem("isPlayingFrom"))?.id === video?.songId
    ) {
      return true;
    }
    return false;
  },[video]);

  useEffect(() => {
    updateWebPageIcon(video?.albumArt)
    return () => {}
  },[])

  useEffect(() => {
    if(!isAlbumArtLoaded){
      getResizedImage(video?.thumbnail).then(image => {
        setAlbumArt(image)
        
      }).catch(err => {
        setAlbumArt(video?.thumbnail)
      })
      setAlbumArtLoaded(true)
    }
  }, [])


  useEffect(() => {
    setLoaded(false)
    setASLoaded(false)
    setNFTLoaded(false)
    if(!isLoaded){
      getSimilarVideos(video?.videoId, 0).then(data => {
        if(data){

          setSimilarVideos(data)
          setLoaded(true)
        }
      }).catch(err => {
        setLoaded(true)
        console.error(err)
        return
  
      })
      searchArtistVideos(video?.artists[0]?.artistId, 0).then(data => {
        if(data){
          setArtistSongs(data)
          setASLoaded(true)
        }
      }).catch(err => {
        setASLoaded(true)
        console.error(err)
        return
  
      })
    getNFTokenCollectionsByVideo(video?.videoId, 0).then(data => {
      if(data){
        setCollections(data)
        
      }
      setNFTLoaded(true)
    }).catch(err => {
      setNFTLoaded(true)
      console.error(err)
      return

    })
    }
      return () => {}
  }, [id])
 

  return (
    <>
      <MusicPageWrapper>
        <MusicHeadingContainer>
          <MusicHeadingBgImage
           onContextMenu={(e) => {
            e.preventDefault();
          }}
            src={isAlbumArtLoaded ? albumArt : video?.albumArt}
            alt={video?.title}
          ></MusicHeadingBgImage>
          <MusicHeadingWrapper style={{alignItems: 'center'}}>
            
            <VideoPlayer content={video} stopMainPlayer={stopMainPlayer} isPlaying={isSongPlaying} validateSubscription={true}/>
            <MusicHeadingTextWrapper>
            <SubHeading><small>{`Video`}</small></SubHeading>

              <MusicHeading>{video?.explicit && <MdExplicit/>}{video?.title}</MusicHeading>
              <MusicSubheading>
                <MusicHeading
                  style={{ cursor: "pointer" }}
                >
                  {video?.artists?.map((artist, index) => {
                    if (index < video?.artists.length - 1) {
                      return <div key={artist?.artistId} style={{marginRight: '0.5rem'}} onClick={() =>
                        goTo(`/artist/${artist.artistId}`)
                      }>{artist.artistName + " · "}</div>;
                    } else {
                      return <div key={artist?.artistId} onClick={() =>
                        goTo(`/artist/${artist.artistId}`)
                      }>{artist.artistName}</div>;
                    }
                  })}
                </MusicHeading>
              </MusicSubheading>
              <MusicHeadingSmall>{`${video?.genre?.name} · ${video?.isPreRelease ? `Pre Release ·` : ""}${video?.isPreRelease ? `${new Date(timestampToDate(video?.releaseDate)).toLocaleString('default', { month: 'long' })} ${new Date(timestampToDate(video?.releaseDate)).getDate()}, ${new Date(timestampToDate(video?.releaseDate)).getFullYear()}` : new Date(timestampToDate(video?.releaseDate)).getFullYear()}`}</MusicHeadingSmall>

            </MusicHeadingTextWrapper>
          </MusicHeadingWrapper>
        </MusicHeadingContainer>
        
          <>
            <MusicDetailsContainer>
            {isNFTLoaded && collections?.length > 0 && <ContentWrapper
                  heading={`NFToken Collections Containing The Song ${video?.title}`}
                >
                  <Slider
                    type={SLIDER_TYPES.COLLECTION}
                    id={"collections"}
                    slides={collections?.collections}
                  />
                </ContentWrapper>}
                {!isNFTLoaded && <ContentWrapper
                  heading={`NFToken Collections Containing The Video ${video?.title}`}
                  onClick={() => collections.length > 10 ? navigate(`/video/${video?.videoId}/collections`) : false}
                  subheading={collections?.length > 10 && "Show All"}
                >
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"collections"}
                  />
                </ContentWrapper>}
                {isLoaded && similarVideos?.length > 0 && <ContentWrapper heading={`Similar Videos to ${video.title}`} onClick={() => similarVideos?.length > 10 &&
                  navigate(`/video/${video.videoId}/similarVideos`)
                } subheading={similarVideos?.length > 10 && 'View All'}>
                  <Slider
                    type={SLIDER_TYPES.VIDEO}
                    id={"recommended videos"}
                    slides={
                      similarVideos.videos
                    }
                  />
                </ContentWrapper>}
                {!isLoaded && <ContentWrapper heading={`Similar Videos to ${video?.title}`}>
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"recommended videos"}
                  />
                </ContentWrapper>}
                {isASLoaded && (artistVideos?.length > 0 && artistVideos?.videos.filter(s => s.videoId !== video?.videoId)?.length > 0) && <ContentWrapper
                  heading={`Videos by ${video?.artists[0]?.artistName}`}
                  onClick={() => artistVideos?.length > 10 && 
                    navigate(`/artist/${video?.artists[0]?.artistId}/videos`)
                  }
                  subheading={artistVideos?.length > 10 && "View All"}
                >
                  <Slider
                    type={SLIDER_TYPES.VIDEO}
                    id={"videos by artist"}
                    slides={artistVideos?.videos?.filter(s => s.videoId !== video?.videoId)}
                  />
                </ContentWrapper>}
                {!isASLoaded && <ContentWrapper
                  heading={`Videos by ${video?.artists[0]?.artistName}`}
                >
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"videos by artist"}
                  />
                </ContentWrapper>}

            </MusicDetailsContainer>
      
          </>
      </MusicPageWrapper>
    </>
  );
}

export default VideoPage;
