import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import useMusic from "../../hooks/useMusic";
import { useUser } from "../../hooks/useUser";
import { downloadFile, getObjectLength, getSongTitle, validateAddress } from "../../utils";
import {
  ALERT_TYPES,
  MUSIC_PURCHASE_OPTIONS,
  TRANSACTION_OPTIONS,
} from "../../utils/Enum";
import { downloadFiles } from "../../utils/FileUploadsUtils";
import { useAlert } from "../Alert/AlertContext";
import {
  FormButton,
  FormImg,
  FormImgWrapper,
  FormInput,
  FormLabel,
  FormText,
} from "../CustomForm/CustomFormElements";
import { ImageViewer } from "../ImageViewer";
import LoadingScreen, { ModalLoadingScreen } from "../LoadingScreen";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { useModal } from "../Modal/ModalContext";
import NFTokenCollectionGifter from "../NFTokenCollectionGifter";
import OptionModal from "../OptionModal";
import QRCodeForm from "../QRCodeForm";
import { Button1, CustomButton, CustomButton1 } from "../ButtonElement";

const PurchaseModal = ({ content, type }) => {
  const {
    toggleModal,
    modalContent,
    isOpen,
    closeModal,
    openModal,
    setCloseButton,
    noCloseModal,
  } = useModal();
  const { addAlert } = useAlert();
  const { currentUser, getUserPurchases } = useAuth();
  const navigate = useNavigate();
  const { downloadMusic } = useMusic();
  const { getRedeemableDiscounts, hasWhitelist, validateWhitelist } = useUser(
    {}
  );
  const quantityRef = useRef(1);
  const commentRef = useRef("");
  const totalRef = useRef(0);
  const [quantity, setQuantity] = useState(1);
  const [comment, setComment] = useState();
  const [hasWList, setHasWList] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [total, setTotal] = useState(content?.price ? content.price : 0);

  useEffect(() => {
    if (type === MUSIC_PURCHASE_OPTIONS.NFT_PURCHASE) {
      hasWhitelist(content?.collectionId)
        .then((hasWL) => {
          setHasWList(hasWL);
          setLoaded(true);
          return;
        })
        .catch((err) => {
          setHasWList(false);
          setLoaded(true);
          return;
        });
    } else {
      setLoaded(true);
    }
    return () => {};
  }, [type]);

  const makePurchase = () => {
    switch (type) {
      case MUSIC_PURCHASE_OPTIONS.DIGITAL_ALBUM_PURCHASE:
        return (
          <OptionModal
            isOpen={true}
            heading={`${content.isPreRelease ? 'Pre-Ordering' : `Purchasing`} The Album: ${content.albumName}`}
            onCancel={() => {
              toggleModal();
              modalContent();
            }}
          >
            <FormInput
              ref={commentRef}
              name={"comment"}
              onChange={handleInput}
              placeholder={"Optional comment"}
              value={comment}
            />
            <FormText style={{ fontSize: "2rem" }}>
              {" "}
              Total:{" "}
              {parseFloat(content?.price) > 0
                ? `${total} ${content?.currency ? content?.currency : "XRP"}`
                : "FREE"}
            </FormText>
            {content?.price > 0 && (
              <>
                <HoverOption
                  onClick={() => {
                    confirmAlbumPurchase(comment, false);
                  }}
                >
                  Purchase
                </HoverOption>

                {/* <HoverOption
                  onClick={() => {
                    confirmPathFinding();
                  }}
                >
                  Purchase With A Currency (Token) of Your Choice
                </HoverOption> */}
              </>
            )}
            {(!content?.price || parseFloat(content?.price) === 0) && (
              <HoverOption
                onClick={() => {
                  modalContent(<PickYourPrice content={content} type={type} />);
                }}
              >
                Pick Your Price
              </HoverOption>
            )}
          </OptionModal>
        );

      case MUSIC_PURCHASE_OPTIONS.DIGITAL_SONG_PURCHASE:
        return (
          <OptionModal
            isOpen={true}
            heading={`${content.isPreRelease ? 'Pre-Ordering' : `Purchasing`} The Song: ${getSongTitle(content)}`}
            onCancel={() => {
              toggleModal();
              modalContent();
            }}
          >
            <FormInput
              ref={commentRef}
              name={"comment"}
              onChange={handleInput}
              placeholder={"Optional comment"}
              value={comment}
            />
            <FormText style={{ fontSize: "2rem" }}>
              {" "}
              Total:{" "}
              {parseFloat(content?.price) > 0
                ? `${total} ${content?.currency ? content?.currency : "XRP"}`
                : "FREE"}
            </FormText>
            {content?.price > 0 && (
              <>
                <HoverOption
                  onClick={() => {
                    confirmSongPurchase(comment, false);
                  }}
                >
                  Purchase
                </HoverOption>

                {/* <HoverOption
                  onClick={() => {
                    confirmPathFinding();
                  }}
                >
                  Purchase With A Currency (Token) of Your Choice
                </HoverOption> */}
              </>
            )}
            {(!content?.price || parseFloat(content?.price) === 0) && (
              <HoverOption
                onClick={() => {
                  modalContent(<PickYourPrice content={content} type={type} />);
                }}
              >
                Pick Your Price
              </HoverOption>
            )}
          </OptionModal>
        );

      case MUSIC_PURCHASE_OPTIONS.NFT_PURCHASE:
        return (
          <OptionModal
            isOpen={true}
            heading={`${content.isPreRelease ? 'Pre-Minting' : `Purchasing  (Minting)`} : ${content.name} NFToken Collection`}
            onCancel={() => {
              toggleModal();
              modalContent();
            }}
          >
            {/* <FormInput name={'quantity'} placeholder={"Quantity"} 
          onChange={(e) => {
              handleInput(e) 
              }} type={'number'} min={1} value={quantity}/> */}

            <FormText style={{ fontSize: "2rem" }}>
              {" "}
              Total:{" "}
              {parseFloat(content?.price) > 0
                ? `${total} ${content?.currency ? content?.currency : "XRP"}`
                : "FREE"}
            </FormText>
            <FormInput
              name={"comment"}
              onChange={handleInput}
              placeholder={"Optional comment"}
              value={comment}
            />
            {content?.price > 0 && (
              <>
                {(getObjectLength(content?.discounts) > 0 || hasWList) && (
                  <CustomButton1
                    background={content?.color}
                    onClick={() => {
                      getAvailableDiscounts();
                    }}
                  >
                    {!hasWList
                      ? `Redeem Discount`
                      : hasWList &&
                        getObjectLength(content?.discounts)?.length > 0
                      ? `Whitelist Mint`
                      : "Redeem Discount / Whitelist Mint"}
                  </CustomButton1>
                )}

                <HoverOption
                  onClick={() => {
                    confirmNFTokenPurchase(quantity, comment, false, 0);
                  }}
                >
                  Purchase
                </HoverOption>
                <HoverOption
                  onClick={() => {
                    modalContent(
                      <>
                        <NFTokenCollectionGifter
                          collection={content}
                          isPurchase
                        />
                      </>
                    );
                  }}
                >
                  Purchase As Gift
                </HoverOption>

                {/* <HoverOption
                  onClick={() => {
                    confirmPathFinding();
                  }}
                >
                  Purchase With A Currency (Token) of Your Choice
                </HoverOption> */}
              </>
            )}
            {(!content?.price || parseFloat(content?.price) === 0) && (
              <HoverOption
                onClick={() => {
                  modalContent(<PickYourPrice content={content} type={type} />);
                }}
              >
                Pick Your Price
              </HoverOption>
            )}
          </OptionModal>
        );

      case MUSIC_PURCHASE_OPTIONS.FREE_MINT:
        getWhitelistEligibility();
        break;
      default:
        closeModal();
        modalContent();
        break;
    }
  };

  const onCollectSuccess = (data) => {
    modalContent(
      <OptionModal
        isOpen={true}
        heading={`The NFToken from Collection ${data.collection.name} #${data.collection.mintInfo.taxon} has been claimed!`}
      >
        <FormImgWrapper>
          <FormImg
            src={data?.collection?.cover?.url}
            alt={data.collection.name}
          />
        </FormImgWrapper>
        <FormText>
          {" "}
          {`Token ID:  ${data.tokenId.substring(0, 10)}... Minted!`}
        </FormText>
        <HoverOption onClick={() => {
          closeModal()
          modalContent()
        }}>OK</HoverOption>
        <HoverOption
          onClick={() => {
            modalContent();
            toggleModal();
            navigate(`/nftoken/${data.tokenId}`);
          }}
        >
          View NFToken
        </HoverOption>
        <HoverOption
          onClick={() => {
            closeModal();
            modalContent();
            navigate(`/musicDownloader`, { preventScrollReset: false });
          }}
        >
          Download NFToken Music
        </HoverOption>
        {data?.offers.length > 0 && (
          <HoverOption
            onClick={() =>
              acceptOffers(data?.offers, data.tokenId, data?.collection)
            }
          >
            Collect Next Token Purchase
          </HoverOption>
        )}
      </OptionModal>
    );
  };

  const onNFTokenPurchaseSuccess = (data) => {
    modalContent(
      <OptionModal
        isOpen={true}
        heading={`The NFT Collection ${content?.name} has been purchased`}
      >
        <FormImgWrapper>
          <FormImg src={content?.cover?.url} alt={content.name} />
        </FormImgWrapper>

        <HoverOption
          onClick={() => {
            acceptOffers(
              data?.offers,
              data?.offers?.uid,
              data.offers[0].tokenId,
              content
            );
          }}
        >
          Claim Token(s) Now
        </HoverOption>
        <HoverOption
          onClick={() => {
            downloadFile(
              `https://xrplexplorer.com/explorer/${data.offers[0]?.tokenId}`
            );
          }}
        >
          View Transaction {data.offers[0].tokenId.substring(0, 15)}...
        </HoverOption>
        <HoverOption
          onClick={() => {
            toggleModal();
            modalContent();
          }}
        >
          Collect Later
        </HoverOption>
      </OptionModal>
    );
  };

  const onAlbumPurchaseSuccess = (data) => {
    getUserPurchases();
    modalContent(
      <OptionModal
        isOpen={true}
        heading={`The Album ${content?.albumName} has been ${content.isPreRelease ? 'pre-ordered' : `purchased`} `}
        additionalText={content.isPreRelease && 'You will be able to download the full album once it has officially released'}
      >
        <FormImgWrapper>
          <FormImg src={content?.albumArt} alt={content?.albumName} />
        </FormImgWrapper>
        <HoverOption
          onClick={() => {
            downloadFile(`https://xrplexplorer.com/explorer/${data?.txid}`);
          }}
        >
          View Transaction {data?.txid?.substring(0, 15)}...
        </HoverOption>
        <HoverOption
          onClick={async () => {
            /*  addAlert({
              title: "Starting Download",
              type: ALERT_TYPES.INFO,
              message: `Processing download. Please Do Not Close Tab.`,
            });
            let content1 = await downloadMusic(
              data?.address,
              type,
              content?.albumId
            );
            await downloadFiles(content1);
            addAlert({
              title: "Music Download",
              type: ALERT_TYPES.SUCCESS,
              message: `Download in progress`,
            });*/
            closeModal();
            modalContent();
            navigate(`/musicDownloader`, { preventScrollReset: false });
          }}
        >
          Download Album
        </HoverOption>
        <HoverOption
          onClick={() => {
            toggleModal();
            modalContent();
          }}
        >
          OK
        </HoverOption>
      </OptionModal>
    );
  };

  const onSongPurchaseSuccess = (data) => {
    getUserPurchases();
    modalContent(
      <OptionModal
        isOpen={true}
        heading={`The song ${getSongTitle(
          content
        )} has successfully been ${content.isPreRelease ? 'pre-ordered' : `purchased`}`}
        additionalText={(content?.isPreRelease && !content?.isPreview) && 'You will be able to download the song once it has officially released'}
      >
        <FormImgWrapper>
          <FormImg src={content.albumArt} alt={content.songName} />
        </FormImgWrapper>
        <HoverOption
          onClick={() => {
            downloadFile(`https://xrplexplorer.com/explorer/${data?.txid}`);
          }}
        >
          View Transaction {data?.txid?.substring(0, 15)}...
        </HoverOption>
        {(!content?.isPreRelease || (content?.isPreRelease && !content?.isPreview)) && <HoverOption
          onClick={async () => {
            /*  addAlert({
              title: "Starting Download",
              type: ALERT_TYPES.INFO,
              message: `Processing download. Please Do Not Close Tab.`,
            });
            let content1 = await downloadMusic(
              data?.address,
              type,
              content?.songId
            );
            await downloadFiles(content1);
            addAlert({
              title: "Music Download",
              type: ALERT_TYPES.SUCCESS,
              message: `Download in progress`,
            }); */
            closeModal();
            modalContent();
            navigate(`/musicDownloader`, { preventScrollReset: false });
          }}
        >
          Download Song
        </HoverOption>}
        <HoverOption
          onClick={() => {
            toggleModal();
            modalContent();
          }}
        >
          OK
        </HoverOption>
      </OptionModal>
    );
  };

  const handleInput = (e) => {
    switch (e.target.name) {
      case "quantity":
        setQuantity(e.target.value);
        setTotal(e.target.value * content.price);
        break;
      case "comment":
        setComment(e.target.value);
        break;
      default:
        break;
    }
  };

  const confirmAlbumPurchase = (comment, pathfinding) => {
    //setCloseButton(false)
    openModal();
    noCloseModal(
      <QRCodeForm
        heading={`${content.isPreRelease ? 'Pre-ordering' : `Purchasing`} (Digital) Album: ${content?.albumName}`}
        type={TRANSACTION_OPTIONS.DIGITAL_ALBUM_PURCHASE}
        cancelQr={() => {
          closeModal();
          modalContent();
        }}
        onFail={() => {
          closeModal();
          modalContent();
        }}
        onSuccess={onAlbumPurchaseSuccess}
        body={{
          pathfinding: true,
          albumId: content?.albumId,
          comment: comment?.trim()?.length > 0 ? comment?.trim() : undefined,
          album: content,
          currency: content?.currency,
          value: content?.price,
          instruction: `${
            currentUser?.uid ? currentUser?.uid : "user"
          } is making a payment to ${content.isPreRelease ? 'pre-order' : `buy`} (digital) the album ${
            content?.albumName
          } (id: ${content?.albumId})`,
          memo: `${
            currentUser?.uid ? currentUser?.uid : "user"
          } is making a payment to ${content.isPreRelease ? 'pre-order' : `buy`} (digital) the album ${
            content?.albumName
          } (id: ${content?.albumId})`,
          sender: currentUser?.uid,
        }}
      />
    );
  };

  const onSignInSuccess = (address) => {
    openModal();
    modalContent(
      <OptionModal>
        <ModalLoadingScreen
          text={"Loading Discounts / Available Whitelist Mints"}
          transparent
        />
      </OptionModal>
    );
    if (type === MUSIC_PURCHASE_OPTIONS.FREE_MINT) {
      getUserWhitelist(address);
    } else {
      getRedeemables(address);
    }
  };

  const onSignInFail = () => {
    modalContent(makePurchase());
  };

  const getWhitelistEligibility = async () => {
    addAlert({
      title: "Getting Available Free Mints",
      type: ALERT_TYPES.INFO,
      secs: 10,
    });
    openModal();
    noCloseModal(
      <OptionModal>
        <ModalLoadingScreen
          text={"Validating Whitelist Mint. Please Wait"}
          transparent
        />
      </OptionModal>
    );
    if (currentUser?.uid) {
      getUserWhitelist(currentUser?.uid);
    } else {
      openModal();
      modalContent(
        <QRCodeForm
          heading={"Discount Redeemer Sign In"}
          onSuccess={onSignInSuccess}
          onFail={onSignInFail}
          cancelQr={() => {
            closeModal();
            modalContent();
            return;
          }}
          type={TRANSACTION_OPTIONS.GET_MUSIC_LIBRARY}
          body={{
            memo: "User is signing transaction to validate whitelist mint",
            instruction:
              "User is signing transaction to validate whitelist mint",
          }}
        />
      );
    }
  };

  const getUserWhitelist = async (address) => {
    return await validateWhitelist(
      address,
      content?.collectionId,
      content?.discounts
    ).then((discounts) => {
      openModal();
      modalContent(
        <OptionModal heading={`Available Discounts For ${content?.name}`}>
          {discounts ? (
            <>
              <HoverOption
                onClick={() => {
                  confirmNFTokenPurchase(quantity, comment, true, 100, true);
                }}
              >
                FREE PURCHASE (MINT)
              </HoverOption>
            </>
          ) : (
            <>
              <FormText>No Discounts Found</FormText>
              <HoverOption
                onClick={() => {
                  confirmNFTokenPurchase(quantity, comment, false, 0, false);
                }}
              >
                Purchase for {total} {content?.currency}
              </HoverOption>
            </>
          )}
          <HoverOption
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </HoverOption>
        </OptionModal>
      );
    });
  };

  const getAvailableDiscounts = async () => {
    addAlert({
      title: "Getting Available Discounts/Free Mints",
      type: ALERT_TYPES.INFO,
      secs: 10,
    });
    openModal();
    modalContent(
      <OptionModal>
        <ModalLoadingScreen
          text={"Loading Discounts / Available Whitelist Mints"}
          transparent
        />
      </OptionModal>
    );
    if (currentUser?.uid) {
      getRedeemables(currentUser?.uid);
    } else {
      modalContent(
        <QRCodeForm
          heading={"Discount Redeemer Sign In"}
          onSuccess={onSignInSuccess}
          onFail={onSignInFail}
          cancelQr={() => {
            if (type === MUSIC_PURCHASE_OPTIONS.FREE_MINT) {
              closeModal();
              modalContent();
              return;
            } else {
              makePurchase();
            }
          }}
          type={TRANSACTION_OPTIONS.GET_MUSIC_LIBRARY}
          body={{
            memo: "User is signing transaction to retreive discounts to redeem",
            instruction:
              "User is signing transaction to retreive discounts to redeem",
          }}
        />
      );
    }
  };

  const getRedeemables = async (address) => {
    return await getRedeemableDiscounts(
      address,
      content?.collectionId,
      content?.discounts
    ).then((discounts) => {
      modalContent(
        <OptionModal heading={`Available Discounts For ${content?.name}`}>
          {discounts?.length > 0 ? (
            discounts.map((d) => {
              return (
                <>
                  <HoverOption
                    onClick={() => {
                      confirmNFTokenPurchase(
                        quantity,
                        comment,
                        true,
                        d,
                        d === 100 ? true : undefined
                      );
                    }}
                  >
                    {d === 100
                      ? `FREE PURCHASE (MINT)`
                      : ` Purchase for ${
                          content?.price - content?.price * (d / 100)
                        } 
                      ${
                        content?.currency ? content?.currency : "XRP"
                      }  (${d}% off)`}
                  </HoverOption>
                </>
              );
            })
          ) : (
            <>
              <FormText>'No Discounts Found'</FormText>
              <HoverOption
                onClick={() => {
                  confirmNFTokenPurchase(quantity, comment, false, 0, false);
                }}
              >
                Purchase for {total} {content?.currency}
              </HoverOption>
            </>
          )}
          <HoverOption
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </HoverOption>
        </OptionModal>
      );
    });
  };

  const confirmSongPurchase = (comment, pathfinding) => {
    //setCloseButton(false)
    openModal();
    noCloseModal(
      <QRCodeForm
        heading={`Purchasing (Digital) Song: ${getSongTitle(content)}`}
        type={TRANSACTION_OPTIONS.DIGITAL_SONG_PURCHASE}
        cancelQr={() => {
          toggleModal();
          modalContent();
        }}
        onFail={() => {
          closeModal();
          modalContent();
        }}
        onSuccess={onSongPurchaseSuccess}
        body={{
          songId: content?.songId,
          comment: comment?.trim()?.length > 0 ? comment?.trim() : undefined,
          value: content?.price,
          pathfinding: true,
          currency: content?.currency,
          song: content,
          instruction: `${
            currentUser?.uid ? currentUser?.uid : "user"
          } is making a payment to ${content.isPreRelease ? 'pre-order' : `buy`} (digital) the song ${
            content?.songName
          } (id: ${content?.songId})`,
          memo: `${
            currentUser?.uid ? currentUser?.uid : "user"
          } is making a payment to ${content.isPreRelease ? 'pre-order' : `buy`} (digital) the song ${
            content?.songName
          } (id: ${content?.songId})`,
          sender: currentUser?.uid,
        }}
      />
    );
  };

  const confirmNFTokenPurchase = (
    quantity,
    comment,
    pathfinding,
    discount,
    isWhitelist
  ) => {
    //setCloseButton(false)
    openModal();
    noCloseModal(
      <QRCodeForm
        heading={`Purchasing (Mint) NFToken(s) from Collection: ${content?.name}`}
        type={TRANSACTION_OPTIONS.NFTOKEN_PURCHASE}
        cancelQr={() => {
          toggleModal();
          modalContent();
        }}
        onFail={() => {
          toggleModal();
          modalContent();
        }}
        onSuccess={onNFTokenPurchaseSuccess}
        body={{
          pathfinding: true,
          collectionId: content?.collectionId,
          comment: comment?.trim()?.length > 0 ? comment?.trim() : undefined,
          quantity: quantity,
          currency: content?.currency,
          collection: content,
          total: discount > 0 ? total - total * (discount / 100) : total,
          isWhitelist: isWhitelist,
          instruction: `${
            currentUser?.uid ? currentUser?.uid : "user"
          } is making a payment to ${content.isPreMint ? 'pre-mint' : `mint`} ${quantity} NFT(s) from the collection ${
            content?.name
          } (id: ${content?.collectionId})`,
          memo: `${
            currentUser?.uid ? currentUser?.uid : "user"
          } is making a payment to ${content.isPreMint ? 'pre-mint' : `mint`} ${quantity} NFT(s) from the collection ${
            content.name
          } (id: ${content?.collectionId})`,
          sender: currentUser?.uid,
        }}
      />
    );
  };

  const confirmPathFinding = () => {
    //setCloseButton(false)
    openModal();
    noCloseModal(
      <OptionModal
        additionalText={`Note: If you try to pay with ${content?.currency}, the transaction will fail!`}
        heading={`You are about to confirm paying with a token other than ${content?.currency}`}
      >
        <HoverOption
          onClick={() => {
            if (type === MUSIC_PURCHASE_OPTIONS.DIGITAL_ALBUM_PURCHASE) {
              return confirmAlbumPurchase(comment, true);
            }
            if (type === MUSIC_PURCHASE_OPTIONS.DIGITAL_SONG_PURCHASE) {
              return confirmSongPurchase(comment, true);
            }
            if (type === MUSIC_PURCHASE_OPTIONS.NFT_PURCHASE) {
              return confirmNFTokenPurchase(quantity, comment, true, 0);
            }
          }}
        >
          Continue
        </HoverOption>
        <HoverOption onClick={() => modalContent(makePurchase())}>
          Cancel
        </HoverOption>
      </OptionModal>
    );
  };

  const acceptOffers = (offers, uid, tokenId, collection) => {
    //console.log('AO: ',tokenId.substring(0,5))
    openModal();
    modalContent(
      <QRCodeForm
        heading={`Claim ${tokenId.substring(0, 5)}... from the ${
          collection.name
        } NFT Collection`}
        type={TRANSACTION_OPTIONS.NFTOKEN_OFFERS_ACCEPT}
        cancelQr={() => {
          toggleModal();
          modalContent();
        }}
        onFail={() => {
          closeModal();
          modalContent();
        }}
        onSuccess={onCollectSuccess}
        body={{
          collection: collection,
          tokenId: tokenId,
          offers: offers,
          sender: currentUser?.uid ? currentUser?.uid : offers[0]?.uid,
          memo: `user is collecting/claiming their token ${tokenId.substring(
            0,
            5
          )}...} NFT(s) from the collection ${collection.name} (id: ${
            collection?.collectionId
          })`,
          instruction: `user is collecting/claiming their token ${tokenId.substring(
            0,
            5
          )}...} NFT(s) from the collection ${collection.name} (id: ${
            collection?.collectionId
          })`,
        }}
      />
    );
  };

  return (
    <>
      {isLoaded && makePurchase()}
      {!isLoaded && <ModalLoadingScreen transparent={true} />}
    </>
  );
};

const PickYourPrice = ({ content, type, comment }) => {
  const {
    toggleModal,
    modalContent,
    isOpen,
    closeModal,
    openModal,
    setCloseButton,
    noCloseModal,
  } = useModal();
  const { addAlert } = useAlert();
  const { currentUser, getUserPurchases } = useAuth();
  const navigate = useNavigate();
  const { downloadMusic } = useMusic();
  const [price, setPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const handleInput = (e) => {
    switch (e.target.name) {
      case "price":
        setPrice(e.target.value);
        setTotal(e.target.value * quantity);
        break;
      default:
        break;
    }
  };

  const makePurchase = () => {
    switch (type) {
      case MUSIC_PURCHASE_OPTIONS.DIGITAL_ALBUM_PURCHASE:
        return (
          <OptionModal
            isOpen={true}
            heading={`Purchasing The Album: ${content.albumName}`}
            additionalText={"Enter Any Price or Leave 0 to get Album for free"}
            onCancel={() => {
              toggleModal();
              modalContent();
            }}
          >
            <FormInput
              name={"price"}
              onChange={handleInput}
              placeholder={"Pick Your Own Price"}
              type="number"
              min={0}
              value={price}
            />
            <FormText>
              {" "}
              Total:{" "}
              {price > 0
                ? `${total} ${content?.currency ? content?.currency : "XRP"}`
                : "FREE"}
            </FormText>
            <HoverOption
              onClick={() => {
                confirmAlbumPurchase(comment);
              }}
            >
              Purchase
            </HoverOption>
          </OptionModal>
        );

      case MUSIC_PURCHASE_OPTIONS.DIGITAL_SONG_PURCHASE:
        return (
          <OptionModal
            isOpen={true}
            heading={`Purchasing The Song: ${getSongTitle(content)}`}
            additionalText={"Enter Any Price or Leave 0 to get Song for free"}
            onCancel={() => {
              toggleModal();
              modalContent();
            }}
          >
            <FormInput
              name={"price"}
              onChange={handleInput}
              placeholder={"Pick Your Own Price"}
              type="number"
              min={0}
              value={price}
            />
            <FormText>
              {" "}
              Total:{" "}
              {content?.price > 0
                ? `${total} ${content?.currency ? content?.currency : "XRP"}`
                : "FREE"}
            </FormText>
            <HoverOption
              onClick={() => {
                confirmSongPurchase(comment);
              }}
            >
              Purchase
            </HoverOption>
          </OptionModal>
        );

      case MUSIC_PURCHASE_OPTIONS.NFT_PURCHASE:
        return (
          <OptionModal
            isOpen={true}
            heading={`Purchase (Minting): ${content.name} NFToken Collection`}
            additionalText={"Enter Any Price or Leave 0 to get NFT for free"}
            onCancel={() => {
              toggleModal();
              modalContent();
            }}
          >
            {/* <FormInput name={'quantity'} placeholder={"Quantity"} 
          onChange={(e) => {
              handleInput(e) 
              }} type={'number'} min={1} value={quantity}/> */}
            <FormInput
              name={"price"}
              onChange={handleInput}
              placeholder={"Pick Your Own Price"}
              type="number"
              min={0}
              value={price}
            />
            <FormText>
              {" "}
              Total:{" "}
              {content?.price > 0
                ? `${total} ${content?.currency ? content?.currency : "XRP"}`
                : "FREE"}
            </FormText>
            <HoverOption
              onClick={() => {
                confirmNFTokenPurchase(quantity, comment);
              }}
            >
              Purchase
            </HoverOption>
          </OptionModal>
        );

      default:
        closeModal();
        modalContent();
        break;
    }
  };

  const onCollectSuccess = (data) => {
    modalContent(
      <OptionModal
        isOpen={true}
        heading={`The NFToken from Collection ${data.collection.name} #${data.collection.mintInfo.taxon} has been claimed!`}
      >
        <FormImgWrapper>
          <FormImg
            src={data?.collection?.cover?.url}
            alt={data.collection.name}
          />
        </FormImgWrapper>
        <FormText>
          {" "}
          {`Token ID:  ${data.tokenId.substring(0, 10)}... Minted!`}
        </FormText>
        <HoverOption onClick={() => {
          closeModal()
          modalContent()
        }}>OK</HoverOption>
        <HoverOption
          onClick={() => {
            closeModal();
            modalContent();
            navigate(`/nftoken/${data.tokenId}`);
          }}
        >
          View NFToken
        </HoverOption>
        <HoverOption
          onClick={() => {
            closeModal();
            modalContent();
            navigate(`/musicDownloader`, { preventScrollReset: false });
          }}
        >
          Download NFToken Music
        </HoverOption>
        {data?.offers.length > 0 && (
          <HoverOption
            onClick={() =>
              acceptOffers(
                data?.offers,
                currentUser?.uid,
                data.tokenId,
                data?.collection
              )
            }
          >
            Collect Next Token Purchase
          </HoverOption>
        )}
      </OptionModal>
    );
  };

  const onNFTokenPurchaseSuccess = (data) => {
    modalContent(
      <OptionModal
        isOpen={true}
        heading={`The NFT Collection ${data.collection?.name} has been purchased`}
      >
        <FormImgWrapper>
          <FormImg
            src={data.collection?.cover?.url}
            alt={data.collection?.name}
          />
        </FormImgWrapper>

        <HoverOption
          onClick={() => {
            acceptOffers(
              data?.offers,
              data?.offers[0]?.uid,
              data.offers[0].tokenId,
              content
            );
          }}
        >
          Claim Token(s) Now
        </HoverOption>
        <HoverOption
          onClick={() => {
            downloadFile(
              `https://xrplexplorer.com/explorer/${data.offers[0]?.tokenId}`
            );
          }}
        >
          View Transaction {data.offers[0].tokenId.substring(0, 15)}...
        </HoverOption>
        <HoverOption
          onClick={() => {
            toggleModal();
            modalContent();
          }}
        >
          Collect Later
        </HoverOption>
      </OptionModal>
    );
  };

  const onAlbumPurchaseSuccess = (data) => {
    getUserPurchases();
    modalContent(
      <OptionModal
        isOpen={true}
        heading={`The Album ${content?.albumName} has been purchased`}
      >
        <FormImgWrapper>
          <FormImg src={content?.albumArt} alt={content?.albumName} />
        </FormImgWrapper>
        <HoverOption
          onClick={() => {
            downloadFile(`https://xrplexplorer.com/explorer/${data?.txid}`);
          }}
        >
          View Transaction {data?.txid?.substring(0, 15)}...
        </HoverOption>
        <HoverOption
          onClick={async () => {
            /*  addAlert({
              title: "Starting Download",
              type: ALERT_TYPES.INFO,
              message: `Processing download. Please Do Not Close Tab.`,
            });
            let content1 = await downloadMusic(
              data?.address,
              type,
              content?.albumId
            );
            await downloadFiles(content1);
            addAlert({
              title: "Music Download",
              type: ALERT_TYPES.SUCCESS,
              message: `Download in progress`,
            });*/
            closeModal();
            modalContent();
            navigate(`/musicDownloader`, { preventScrollReset: false });
          }}
        >
          Download Album
        </HoverOption>
        <HoverOption
          onClick={() => {
            toggleModal();
            modalContent();
          }}
        >
          OK
        </HoverOption>
      </OptionModal>
    );
  };

  const onSongPurchaseSuccess = (data) => {
    getUserPurchases();
    modalContent(
      <OptionModal
        isOpen={true}
        heading={`The song ${getSongTitle(
          content
        )} has successfully been purchased`}
      >
        <FormImgWrapper>
          <FormImg src={content.albumArt} alt={content.songName} />
        </FormImgWrapper>
        <HoverOption
          onClick={() => {
            downloadFile(`https://xrplexplorer.com/explorer/${data?.txid}`);
          }}
        >
          View Transaction {data?.txid?.substring(0, 15)}...
        </HoverOption>
        <HoverOption
          onClick={async () => {
            /* addAlert({
              title: "Starting Download",
              type: ALERT_TYPES.INFO,
              message: `Processing download. Please Do Not Close Tab.`,
            });
            let content1 = await downloadMusic(
              data?.address,
              type,
              content?.songId
            );
            await downloadFiles(content1);
            addAlert({
              title: "Music Download",
              type: ALERT_TYPES.SUCCESS,
              message: `Download in progress`,
            });*/
            closeModal();
            modalContent();
            navigate(`/musicDownloader`, { preventScrollReset: false });
          }}
        >
          Download Song
        </HoverOption>
        <HoverOption
          onClick={() => {
            toggleModal();
            modalContent();
          }}
        >
          OK
        </HoverOption>
      </OptionModal>
    );
  };

  const confirmAlbumPurchase = (comment) => {
    //setCloseButton(false)
    openModal();
    noCloseModal(
      <QRCodeForm
        heading={`Purchasing (Digital) Album: ${content?.albumName}`}
        type={TRANSACTION_OPTIONS.DIGITAL_ALBUM_PURCHASE}
        cancelQr={() => {}}
        onFail={() => {
          closeModal();
          modalContent();
        }}
        onSuccess={onAlbumPurchaseSuccess}
        body={{
          pathfinding: true,
          albumId: content?.albumId,
          comment: comment?.trim()?.length > 0 ? comment?.trim() : undefined,
          album: content,
          currency: content?.currency,
          value: content?.price,
          instruction: `${
            currentUser?.uid ? currentUser?.uid : "user"
          } is making a payment to buy (digital) the album ${
            content?.albumName
          } (id: ${content?.albumId})`,
          memo: `${
            currentUser?.uid ? currentUser?.uid : "user"
          } is making a payment to buy (digital) the album ${
            content?.albumName
          } (id: ${content?.albumId})`,
          sender: currentUser?.uid,
        }}
      />
    );
  };

  const confirmSongPurchase = (comment) => {
    //setCloseButton(false)
    openModal();
    noCloseModal(
      <QRCodeForm
        heading={`Purchasing (Digital) Song: ${getSongTitle(content)}`}
        type={TRANSACTION_OPTIONS.DIGITAL_SONG_PURCHASE}
        cancelQr={() => {
          toggleModal();
          modalContent();
        }}
        onFail={() => {
          closeModal();
          modalContent();
        }}
        onSuccess={onSongPurchaseSuccess}
        body={{
          songId: content?.songId,
          comment: comment?.trim()?.length > 0 ? comment?.trim() : undefined,
          value: content?.price,
          pathfinding: true,
          currency: content?.currency,
          song: content,
          instruction: `${
            currentUser?.uid ? currentUser?.uid : "user"
          } is making a payment to buy (digital) the song ${
            content?.songName
          } (id: ${content?.songId})`,
          memo: `${
            currentUser?.uid ? currentUser?.uid : "user"
          } is making a payment to buy (digital) the song ${
            content?.songName
          } (id: ${content?.songId})`,
          sender: currentUser?.uid,
        }}
      />
    );
  };

  const confirmNFTokenPurchase = (quantity, comment) => {
    setCloseButton(false);
    openModal();
    noCloseModal(
      <QRCodeForm
        heading={`Purchasing (Mint) NFToken(s) from Collection: ${content?.name}`}
        type={TRANSACTION_OPTIONS.NFTOKEN_PURCHASE}
        cancelQr={() => {
          toggleModal();
          modalContent();
        }}
        onFail={() => {
          toggleModal();
          modalContent();
        }}
        onSuccess={onNFTokenPurchaseSuccess}
        body={{
          pathfinding: true,
          collectionId: content?.collectionId,
          comment: comment?.trim()?.length > 0 ? comment?.trim() : undefined,
          quantity: quantity,
          currency: content?.currency,
          collection: content,
          total: total,
          instruction: `${
            currentUser?.uid ? currentUser?.uid : "user"
          } is making a payment to mint ${quantity} NFT(s) from the collection ${
            content?.name
          } (id: ${content?.collectionId})`,
          memo: `${
            currentUser?.uid ? currentUser?.uid : "user"
          } is making a payment to mint ${quantity} NFT(s) from the collection ${
            content.name
          } (id: ${content?.collectionId})`,
          sender: currentUser?.uid,
        }}
      />
    );
  };

  const acceptOffers = (offers, uid, tokenId, collection) => {
    openModal();
    //console.log('AO: ',tokenId.substring(0,5))
    modalContent(
      <QRCodeForm
        heading={`Claim ${tokenId.substring(0, 5)}... from the ${
          collection.name
        } NFT Collection`}
        type={TRANSACTION_OPTIONS.NFTOKEN_OFFERS_ACCEPT}
        cancelQr={() => {
          toggleModal();
          modalContent();
        }}
        onFail={() => {
          toggleModal();
          modalContent();
        }}
        onSuccess={onCollectSuccess}
        body={{
          collection: collection,
          tokenId: tokenId,
          payload: offers[0]?.payload,
          offers: offers,
          sender: currentUser?.uid ? currentUser?.uid : offers[0]?.uid,
          memo: `user is collecting/claiming their token ${tokenId.substring(
            0,
            5
          )}...} NFT(s) from the collection ${collection.name} (id: ${
            collection?.collectionId
          })`,
          instruction: `user is collecting/claiming their token ${tokenId.substring(
            0,
            5
          )}...} NFT(s) from the collection ${collection.name} (id: ${
            collection?.collectionId
          })`,
        }}
      />
    );
  };

  return <>{makePurchase()}</>;
};

export const SwerlVoucherPurchase = ({ content, type }) => {
  const {
    toggleModal,
    modalContent,
    isOpen,
    closeModal,
    openModal,
    setCloseButton,
    noCloseModal,
  } = useModal();
  const { addAlert } = useAlert();
  const { currentUser, getUserPurchases } = useAuth();
  const navigate = useNavigate();
  const quantityRef = useRef(1);
  const commentRef = useRef("");
  const totalRef = useRef(0);
  const [quantity, setQuantity] = useState(1);
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(content?.price ? content.price : 0);

  const confirmPurchase = () => {
    //setCloseButton(false)
    openModal();
    noCloseModal(
      <>
        <QRCodeForm
          heading={`Purchasing (Minting) ${content?.name}`}
          type={TRANSACTION_OPTIONS.PURCHASE_SWERL_VOUCHER}
          body={{
            sender: currentUser?.uid,
            total: discount > 0 ? total - total * (discount / 100) : total,
            months: content?.id,
            instruction: `${
              currentUser?.uid ? currentUser?.uid : "user"
            } is making a payment to mint ${quantity} ${content?.name}`,
            memo: `${
              currentUser?.uid ? currentUser?.uid : "user"
            } is making a payment to mint ${quantity} ${content.name}`,
          }}
          onSuccess={onVoucherPurchaseSuccess}
          cancelQr={() => {
            toggleModal();
            modalContent();
          }}
          onFail={() => {
            toggleModal();
            modalContent();
          }}
        />
      </>
    );
  };

  const onVoucherPurchaseSuccess = (data) => {
    try {
      openModal();
      modalContent(
        <OptionModal
          isOpen={true}
          heading={`The ${content.name} has been purchased`}
        >
          <FormImgWrapper>
            <FormImg src={content.cover?.url} alt={content.name} />
          </FormImgWrapper>

          <HoverOption
            onClick={() => {
              acceptOffers(
                data?.offers,
                data?.offers[0]?.uid,
                data.offers[0].tokenId
              );
            }}
          >
            Claim Token(s) Now
          </HoverOption>
          <HoverOption
            onClick={() => {
              downloadFile(
                `https://xrplexplorer.com/explorer/${data.offers[0]?.tokenId}`
              );
            }}
          >
            View Transaction {data.offers[0].tokenId.substring(0, 15)}...
          </HoverOption>
          <HoverOption
            onClick={() => {
              toggleModal();
              modalContent();
            }}
          >
            Collect Later
          </HoverOption>
        </OptionModal>
      );
    } catch (err) {
      console.error(err);
    }
  };

  const onCollectSuccess = (data) => {
    openModal();
    modalContent(
      <OptionModal
        isOpen={true}
        heading={`The ${content.name} has been claimed!`}
      >
        <FormImgWrapper>
          <FormImg src={content?.cover?.url} alt={content.name} />
        </FormImgWrapper>
        <FormText>
          {" "}
          {`Token ID:  ${data.tokenId.substring(0, 10)}... Minted!`}
        </FormText>
        <HoverOption onClick={() => {
          closeModal()
          modalContent()
        }}>OK</HoverOption>
        <HoverOption
          onClick={() => {
            closeModal();
            modalContent();
            navigate(`/nftoken/${data.tokenId}`);
          }}
        >
          View NFToken
        </HoverOption>
        <HoverOption
          onClick={() =>
            navigate(`/musicDownloader`, { preventScrollReset: false })
          }
        >
          Download NFToken Music
        </HoverOption>
        {data?.offers.length > 0 && (
          <HoverOption
            onClick={() =>
              acceptOffers(data?.offers, currentUser?.uid, data.tokenId)
            }
          >
            Collect Next Token Purchase
          </HoverOption>
        )}
      </OptionModal>
    );
  };

  const acceptOffers = (offers, uid, tokenId) => {
    //console.log('AO: ',tokenId.substring(0,5))
    openModal();
    modalContent(
      <QRCodeForm
        heading={`Claim ${tokenId.substring(0, 5)}... from the ${
          content.name
        } NFT Collection`}
        type={TRANSACTION_OPTIONS.SWERL_VOUCHER_OFFERS_ACCEPT}
        cancelQr={() => {
          toggleModal();
          modalContent();
        }}
        onFail={() => {
          closeModal();
          modalContent();
        }}
        onSuccess={onCollectSuccess}
        body={{
          collection: content,
          tokenId: tokenId,
          payload: offers[0]?.payload,
          offers: offers,
          sender: currentUser?.uid ? currentUser?.uid : offers[0]?.uid,
          memo: `user is collecting/claiming their voucher ${tokenId.substring(
            0,
            5
          )}...} `,
          instruction: `user is collecting/claiming their voucher ${tokenId.substring(
            0,
            5
          )}...}`,
        }}
      />
    );
  };

  const makePurchase = () => {
    return (
      <OptionModal
        isOpen={true}
        heading={`Purchasing (Minting): ${content.name}`}
        additionalText={
          "You can purchase this voucher with any token of your choice"
        }
        onCancel={() => {
          toggleModal();
          modalContent();
        }}
      >
        {/* <FormInput name={'quantity'} placeholder={"Quantity"} 
          onChange={(e) => {
              handleInput(e) 
              }} type={'number'} min={1} value={quantity}/> */}
        <FormText>
          {" "}
          Total:{" "}
          {parseFloat(content?.price) > 0
            ? `${total} ${content?.currency ? content?.currency : "XRP"}`
            : "FREE"}
        </FormText>
        {content?.price > 0 && (
          <>
            <HoverOption
              onClick={() => {
                modalContent(
                  <>
                    <OptionModal heading={"Coming Soon"}>
                      <HoverOption
                        onClick={() => {
                          openModal();
                          modalContent(
                            <>
                              <SwerlVoucherPurchase content={content} />
                            </>
                          );
                        }}
                      >
                        OK
                      </HoverOption>
                    </OptionModal>
                  </>
                );
              }}
            >
              Purchase As Gift
            </HoverOption>
            <HoverOption
              onClick={() => {
                confirmPurchase();
              }}
            >
              Purchase
            </HoverOption>

            {/* content?.discounts && <HoverOption onClick={() => {
                //getAvailableDiscounts()
            }}>
          Redeem Discount
          </HoverOption> */}
          </>
        )}
      </OptionModal>
    );
  };

  return <>{makePurchase()}</>;
};

export const RoyaltySharePurchase = ({ content, type }) => {
  const {
    toggleModal,
    modalContent,
    isOpen,
    closeModal,
    openModal,
    setCloseButton,
    noCloseModal,
  } = useModal();
  const { currentUser } = useAuth();
  const { validateUserRoyaltyTrustline } = useUser({});
  const { addAlert } = useAlert();
  const quantityRef = useRef(1);
  const totalRef = useRef(0);
  const [quantity, setQuantity] = useState(1);
  const [isLoaded, setLoaded] = useState(false);
  const [total, setTotal] = useState(
    content?.userRoyalties?.price ? content?.userRoyalties?.price : 0
  );
  const [royaltyInfo, setRoyaltyInfo] = useState(content?.userRoyalties);

  const handleInput = (e) => {
    switch (e.target.name) {
      case "quantity":
        setQuantity(e.target.value);
        setTotal(e.target.value * royaltyInfo.price);
        break;
      default:
        break;
    }
  };

  //getRoyaltyToken info then display info
  useEffect(() => {
    if (!isLoaded) {
      setLoaded(true);
      /*    getMusicRoyaltyInfo(content?.userRoyalty).then((royalty) => {
        if(royalty){
          setRoyaltyInfo(royalty)
          setLoaded(true)
        }else{
          closeModal()
          modalContent()
        }
      }).catch(err => {
        openModal()
        modalContent(<OptionModal heading={'Something went wrong'} onCancel={() => {
          closeModal()
          modalContent()
        }}>
          <HoverOption onClick={() => {
            openModal()
            modalContent(<RoyaltySharePurchase content={content}/>)
          }}>Try Again</HoverOption>

        </OptionModal>)
      }) */
    }

    return () => {};
  }, [content]);

  const checkUserTrustlines = () => {
    try {
      if (!currentUser?.uid) {
        openModal()
        modalContent(
          <QRCodeForm
            heading={"Regium Share Trustline Validation"}
            onSuccess={onSignInSuccess}
            onFail={onSignInFail}
            cancelQr={() => {
              closeModal();
              modalContent();
              return;
            }}
            type={TRANSACTION_OPTIONS.GET_ACCOUNT_INFO}
            body={{
              memo: "User is signing transaction to validate token trustlines",
              instruction:
                "User is signing transaction to validate token trustlines",
            }}
          />
        );
      } else {
        validateUserTrustline(
          currentUser?.uid,
          content?.userRoyalties?.royaltyId
        );
        return (<ModalLoadingScreen text={"Checking Trustlines. Please Wait"} />)
        
      }
    } catch (err) {
      console.error(err);
    }
  };

  const validateUserTrustline = async (address, royaltyId) => {
    await validateUserRoyaltyTrustline(address, royaltyId)
      .then((validation) => {
        if (validation.exists) {
          openModal();
          return modalContent(<RoyaltySharePurchaseForm address={address} content={content} royaltyInfo={royaltyInfo} type={type}/>);
        }
        if (!validation.exists) {
          return modalContent(
            <OptionModal
              heading={`No (${royaltyInfo.ticker}) Regium Share Token Trustline Set for ${
                type === "song"
                  ? content?.songName
                  : type === "album"
                  ? content.albumName
                  : content?.episodeId
              }`}
              additionalText={`You do not have the ${royaltyInfo.ticker} regium trustline set. Please set up trustline before continuing`}
              onCancel={() => {
                closeModal();
                modalContent();
              }}
            >
              <HoverOption
                onClick={() => {
                  createTrustline(address);
                }}
              >
                {" "}
                Continue
              </HoverOption>
            </OptionModal>
          );
        } else {
          openModal();
          return modalContent(
            <OptionModal
              heading={"Something went wrong"}
              onCancel={() => {
                closeModal();
                modalContent();
              }}
            >
              <HoverOption
                onClick={() => {
                  openModal();
                  modalContent(<RoyaltySharePurchase content={content} type={type} />);
                }}
              >
                Try Again
              </HoverOption>
            </OptionModal>
          );
        }
      })
      .catch((err) => {
        openModal();
        return modalContent(
          <OptionModal
            heading={"Something went wrong"}
            onCancel={() => {
              closeModal();
              modalContent();
            }}
          >
            <HoverOption
              onClick={() => {
                openModal();
                modalContent(<RoyaltySharePurchase content={content} type={type}  />);
              }}
            >
              Try Again
            </HoverOption>
          </OptionModal>
        );
      });
  };

  const makePurchase = (address) => {
    openModal()
   modalContent(
      <OptionModal
        isOpen={true}
        heading={`Purchasing Regium Share for ${type} ${
          type === "song"
            ? content?.songName
            : type === "album"
            ? content.albumName
            : content?.episodeName
        } (${royaltyInfo?.ticker})`}
        additionalText={"Enter Any Quantity"}
        onCancel={() => {
          toggleModal();
          modalContent();
        }}
      >
        <FormInput
          name={"quantity"}
          onChange={handleInput}
          placeholder={"How many shares do you want to purchase"}
          type="number"
          min={1}
          max={100 - royaltyInfo.sharesSold}
          value={quantity}
        />
        <FormText>
          {" "}
          Total:{" "}
          {royaltyInfo?.price > 0
            ? `${total} ${
                royaltyInfo?.currency ? royaltyInfo?.currency : "XRP"
              }`
            : "FREE"}
        </FormText>
        <HoverOption
          onClick={() => {
            confirmPurchase(address);
          }}
        >
          Purchase
        </HoverOption>
      </OptionModal>
    );
  };

  const confirmPurchase = (address) => {
    openModal();
    modalContent(
      <QRCodeForm
        heading={`Purchasing ${
          type === "song"
            ? content?.songName
            : type === "album"
            ? content.albumName
            : content?.episodeName
        } Regium Share (${royaltyInfo.ticker})`}
        type={TRANSACTION_OPTIONS.PURCHASE_MUSIC_ROYALTY}
        onFail={onPurchaseFail}
        onSuccess={onPurchaseSuccess}
        cancelQr={() => {
          closeModal();
          modalContent();
        }}
        body={{
          sender: address ? address : currentUser?.uid,
          value: total,
          currency: royaltyInfo?.tokenInfo?.currency,
          ...content,
          pathfinding: true,
          purchaseType: type,
          quantity: quantity,
          instruction: `${address} is signing transaction to purchase ${
            type === "song"
              ? content?.songName
              : type === "album"
              ? content.albumName
              : content?.episodeId
          } royalty share token ${royaltyInfo.ticker}`,
          memo: `${address} is signing transaction to purchase ${
            type === "song"
              ? content?.songName
              : type === "album"
              ? content.albumName
              : content?.episodeId
          } royalty share token ${royaltyInfo.ticker}`,
        }}
      />
    );
  };

  const createTrustline = (address) => {
    openModal();
    modalContent(
      <QRCodeForm
      heading={`Regium Share Trustline Create ${royaltyInfo.ticker}`}
        type={TRANSACTION_OPTIONS.ROYALTY_TRUSTLINE_SET}
        onFail={onTrustlineFail}
        onSuccess={onTrustlineSuccess}
        cancelQr={() => {
          closeModal();
          modalContent();
        }}
        body={{
          sender: address,
          tokenId: royaltyInfo.royaltyId,
        }}
      />
    );
  };

  const onSignInSuccess = ({ address, userToken }) => {
    validateUserTrustline(address, content?.userRoyalties?.royaltyId);
  };

  const onSignInFail = () => {
    closeModal();
    modalContent();
  };

  const onTrustlineSuccess = (data) => {
    openModal();
    modalContent(<RoyaltySharePurchaseForm address={data.address} content={content} royaltyInfo={royaltyInfo} type={type}/>);
  };

  const onTrustlineFail = () => {
    closeModal();
    modalContent();
  };

  const onPurchaseSuccess = (data) => {
    console.log(data)
    openModal();
    modalContent(
      <OptionModal heading={"Regium Share Purchase Successful"} additionalText={`The next royalty share distribution will be on ${new Date(royaltyInfo.nextPayout).getDate()}/${new Date(royaltyInfo.nextPayout).getMonth() + 1}/${new Date(royaltyInfo.nextPayout).getFullYear()}`}>
        <FormImgWrapper>
          <FormImg src={content.albumArt} alt={content.songName} />
        </FormImgWrapper>
        <HoverOption onClick={() => {
          closeModal()
          modalContent()
        }}>OK</HoverOption>
        <HoverOption
          onClick={() => {
            downloadFile(`https://xrplexplorer.com/explorer/${data?.txid}`);
          }}
        >
          View Transaction {data?.txid?.substring(0, 15)}...
        </HoverOption>
      </OptionModal>
    );
  };

  const onPurchaseFail = () => {
    closeModal();
    modalContent();
  };

  return (
    <>
      {checkUserTrustlines()}
    </>
  );
};

export const RoyaltyShareGifter = ({ content, type }) => {
  const {
    toggleModal,
    modalContent,
    isOpen,
    closeModal,
    openModal,
    setCloseButton,
    noCloseModal,
  } = useModal();
  const { currentUser } = useAuth();
  const { validateUserRoyaltyTrustline } = useUser({});
  const { addAlert } = useAlert();
  const quantityRef = useRef(1);
  const totalRef = useRef(0);
  const [quantity, setQuantity] = useState(1);
  const [address, setAddress] = useState("");
  const [isLoaded, setLoaded] = useState(false);
  const [royaltyInfo, setRoyaltyInfo] = useState(content?.userRoyalties);

  const handleInput = (e) => {
    switch (e.target.name) {
      case "quantity":
        if(e.target.value?.length > 0 && (e.target.value <= 100 - royaltyInfo?.sharesSold) && isNaN(e.target.value) === false){
          if(parseInt(e.target.value[0]) === 0){
            setQuantity(parseInt(e.target.value.substring(1,e.target.value.length)));
          return
          }
          setQuantity(parseInt(e.target.value));
          return
          }if(e.target.value?.length === 0){
            setQuantity(0);
          }

        break;
      case "address":
        setAddress(e.target.value);
        break;
      default:
        break;
    }
  };

  //getRoyaltyToken info then display info
  useEffect(() => {
    if (!isLoaded) {
      setLoaded(true);
    }

    return () => {};
  }, [content]);


  const validateUserTrustline = async () => {
    modalContent(<OptionModal><ModalLoadingScreen text={'Checking Wallet Trustlines. Please wait'}/></OptionModal>)
    await validateUserRoyaltyTrustline(address, royaltyInfo?.royaltyId)
      .then((validation) => {
        if (validation.exists) {
          return confirmGift()
        }
        if (!validation.exists) {
          return modalContent(
            <OptionModal
              heading={`No (${royaltyInfo.ticker}) Regium Share Token Trustline Set for ${
                type === "song"
                  ? content?.songName
                  : type === "album"
                  ? content.albumName
                  : content?.episodeId
              } `}
              additionalText={`${address} does not have the ${royaltyInfo.ticker} regium trustline set. which means the share cannot be sent to them. Please contact them before trying to gift tokens to them again`}
            >
              <HoverOption
                onClick={() => {
                  closeModal()
                  modalContent()
                }}
              >
                {" "}
                Close
              </HoverOption>
            </OptionModal>
          );
        } else {
          openModal();
          return modalContent(
            <OptionModal
              heading={"Something went wrong"}
              onCancel={() => {
                closeModal();
                modalContent();
              }}
            >
              <HoverOption
                onClick={() => {
                  openModal();
                  modalContent(<RoyaltyShareGifter content={content} type={type}  />);
                }}
              >
                Try Again
              </HoverOption>
            </OptionModal>
          );
        }
      })
      .catch((err) => {
        openModal();
        return modalContent(
          <OptionModal
            heading={"Something went wrong"}
            onCancel={() => {
              closeModal();
              modalContent();
            }}
          >
            <HoverOption
              onClick={() => {
                openModal();
                modalContent(<RoyaltyShareGifter content={content} type={type} />);
              }}
            >
              Try Again
            </HoverOption>
          </OptionModal>
        );
      });
  };


  const confirmGift = () => {
    openModal();
    modalContent(
      <QRCodeForm
        heading={`Gifting ${
          type === "song"
            ? content?.songName
            : type === "album"
            ? content.albumName
            : content?.episodeName
        } Regium Share (${royaltyInfo.ticker}) to ${address}`}
        type={TRANSACTION_OPTIONS.GIFT_MUSIC_ROYALTY}
        onFail={onGiftFail}
        onSuccess={onGiftSuccess}
        cancelQr={() => {
          closeModal();
          modalContent();
        }}
        body={{
          sender: currentUser?.uid,
          type: type,
          quantity: quantity,
          receiver: address,
          royaltyId: royaltyInfo?.royaltyId,
          instruction: `${currentUser?.uid} is signing transaction to gift ${
            type === "song"
              ? content?.songName
              : type === "album"
              ? content.albumName
              : content?.episodeId
          } royalty share token ${royaltyInfo.ticker} to ${address}`,
          memo: `${currentUser?.uid} is signing transaction to gift ${
            type === "song"
              ? content?.songName
              : type === "album"
              ? content.albumName
              : content?.episodeId
          } royalty share token ${royaltyInfo.ticker} to ${address}`,
        }}
      />
    );
  };


  const onGiftSuccess = (data) => {
    console.log(data)
    openModal();
    modalContent(
      <OptionModal heading={"Regium Share Successfully Gifted "} additionalText={`The next royalty share distribution will be on ${new Date(royaltyInfo.nextPayout).getDate()}/${new Date(royaltyInfo.nextPayout).getMonth() + 1}/${new Date(royaltyInfo.nextPayout).getFullYear()}`}>
        <FormImgWrapper>
          <FormImg src={content.albumArt} alt={content.songName} />
        </FormImgWrapper>
        <HoverOption onClick={() => {
          closeModal()
          modalContent()
        }}>OK</HoverOption>
        <HoverOption
          onClick={() => {
            downloadFile(`https://xrplexplorer.com/explorer/${data?.txid}`);
          }}
        >
          View Transaction {data?.txid?.substring(0, 15)}...
        </HoverOption>
      </OptionModal>
    );
  };

  const onGiftFail = () => {
    closeModal();
    modalContent();
  };

  return (
    <>
      {  <OptionModal
        isOpen={true}
        heading={`Gifting Regium Share for ${type} ${
          type === "song"
            ? content?.songName
            : type === "album"
            ? content.albumName
            : content?.episodeName
        } (${royaltyInfo?.ticker})`}
        
        onCancel={() => {
          toggleModal();
          modalContent();
        }}
      >
        <FormLabel>Wallet address</FormLabel>
        <FormInput name={"address"}
        onChange={handleInput}
        placeholder={"Wallet address to gift share tokens to"}
        value={address}
        />
        <FormLabel>Quantity</FormLabel>
        <FormInput
          name={"quantity"}
          onChange={handleInput}
          placeholder={"How many shares do you want to gift"}
          type="number"
          min={1}
          max={100 - royaltyInfo.sharesSold}
          value={quantity}
        />
        <HoverOption
          onClick={() => {
            if(validateAddress(address)){
              return validateUserTrustline()
            }
            addAlert({
              type: ALERT_TYPES.WARNING,
              message: "Invalid Wallet Address",
            })
          }}
        >
          Gift Share(s)
        </HoverOption>
      </OptionModal>}
    </>
  );
};

const RoyaltySharePurchaseForm = ({royaltyInfo, type, content, address}) => {
  const {openModal, modalContent, closeModal} = useModal()
  const { addAlert } = useAlert();
  const {currentUser} = useAuth()
  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(
    royaltyInfo.price ? royaltyInfo.price : 0
  );
  const quantityRef = useRef(1);
  const totalRef = useRef(0);

  const handleInput = (e) => {
    switch (e.target.name) {
      case "quantity":
        if(e.target.value?.length > 0 && (e.target.value <= 100 - royaltyInfo?.sharesSold ) && isNaN(e.target.value) === false){
          if(parseInt(e.target.value[0]) === 0){
            setQuantity(parseInt(e.target.value.substring(1,e.target.value.length)));
          setTotal(e.target.value * royaltyInfo.price);
          return
          }
          setQuantity(parseInt(e.target.value));
          setTotal(e.target.value * royaltyInfo.price);
          return
          }if(e.target.value?.length === 0){
            setQuantity(0);
            setTotal(0 * royaltyInfo.price);
          }
        
        break;
      default:
        break;
    }
  };

  const onPurchaseSuccess = (data) => {
    openModal();
    modalContent(
      <OptionModal heading={"Regium Share Purchase Successful"} additionalText={`The next royalty share distribution will be on ${new Date(royaltyInfo.nextPayout).getDate()}/${new Date(royaltyInfo.nextPayout).getMonth() + 1}/${new Date(royaltyInfo.nextPayout).getFullYear()}`}>
        <FormImgWrapper>
          <FormImg src={content.albumArt} alt={content.songName} />
        </FormImgWrapper>
        <HoverOption onClick={() => {
          closeModal()
          modalContent()
        }}>OK</HoverOption>
        <HoverOption
          onClick={() => {
            downloadFile(`https://xrplexplorer.com/explorer/${data?.txid}`);
          }}
        >
          View Transaction {data?.txid?.substring(0, 15)}...
        </HoverOption>
      </OptionModal>
    );
  };

  const onPurchaseFail = () => {
    closeModal();
    modalContent();
  };

  const confirmPurchase = (address) => {
    openModal();
    modalContent(
      <QRCodeForm
        heading={`Purchasing ${
          type === "song"
            ? content?.songName
            : type === "album"
            ? content.albumName
            : content?.episodeName
        } Regium Share (Ticker: ${royaltyInfo.ticker})`}
        type={TRANSACTION_OPTIONS.PURCHASE_MUSIC_ROYALTY}
        onFail={onPurchaseFail}
        onSuccess={onPurchaseSuccess}
        cancelQr={() => {
          closeModal();
          modalContent();
        }}
        body={{
          sender: address ? address : currentUser?.uid,
          value: total,
          ...content,
          currency: royaltyInfo?.currency,
          pathfinding: true,
          purchaseType: type,
          quantity: quantity,
          instruction: `${currentUser.uid ? currentUser.uid : address} is signing transaction to purchase ${
            type === "song"
              ? content?.songName
              : type === "album"
              ? content.albumName
              : content?.episodeId
          } royalty share token (ticker: ${royaltyInfo.ticker})`,
          memo: `${currentUser.uid ? currentUser.uid : address} is signing transaction to purchase ${
            type === "song"
              ? content?.songName
              : type === "album"
              ? content.albumName
              : content?.episodeId
          } royalty share token (ticker: ${royaltyInfo.ticker})`,
        }}
      />
    );
  };

  return (
    <OptionModal
        isOpen={true}
        heading={`Purchasing Regium Share for ${type[0].toUpperCase()+type.substring(1)} ${
          type === "song"
            ? content?.songName
            : type === "album"
            ? content.albumName
            : content?.episodeName
        } (ticker: ${royaltyInfo?.ticker})`}
        additionalText={"Enter Any Quantity"}
        onCancel={() => {
          closeModal();
          modalContent();
        }}
      >
        <FormInput
          name={"quantity"}
          onChange={handleInput}
          placeholder={"How many shares do you want to purchase"}
          min={1}
          max={100 - (royaltyInfo.sharesSold)}
          value={quantity}
        />
        <FormText>
          {" "}
          Total:{" "}
          {royaltyInfo?.price > 0
            ? `${total} ${
                royaltyInfo?.currency ? royaltyInfo?.currency : "XRP"
              }`
            : "FREE"}
        </FormText>
        <HoverOption
          onClick={() => {
            if(quantity > 0){
            confirmPurchase(address)
          }else{
            addAlert({
              title: "Purchase Error",
              type: ALERT_TYPES.DANGER,
              message: `Enter valid quantity`,
            });
          }
          }}
        >
          Purchase
        </HoverOption>
      </OptionModal>
  )
}

export default PurchaseModal;
