import React, { useState } from 'react'
import OptionModal from '../OptionModal'
import { useModal } from '../Modal/ModalContext'
import { HoverOption } from '../MenuOptions/MenuOptionsElements'
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,
  } from "react-share";

  import {
    EmailIcon,
    FacebookIcon,
    InstapaperIcon,
    LinkedinIcon,
    PinterestIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    WhatsappIcon,
    XIcon,
  } from "react-share";
import { FormImg, FormImgWrapper, FormInput, FormInput1, FormWrapper } from '../CustomForm/CustomFormElements';
import { ShareButtonClipboardButton, ShareButtonClipboardText, ShareButtonClipboardWrapper } from './ShareButtonElements';
import { useAlert } from '../Alert/AlertContext';
import { ALERT_TYPES } from '../../utils/Enum';
import { copyTextToClipboard } from '../../utils';

const ShareButton = ({url, title, media, name}) => {
    const {closeModal, modalContent} = useModal()
    const {addAlert} = useAlert()
    const [isCopied, setCopied] = useState(false)
  return (
    <>
        <OptionModal heading={`Share`} additionalText={'Share this amazing content with the world!'} onCancel={() => {
            closeModal()
            modalContent()
        }}>   
        {media && <FormImgWrapper>
          <FormImg src={media} alt={'Sonar Muse'} />
        </FormImgWrapper>}
                <FormWrapper  row style={{ display: 'grid', gap: "5%", gridTemplateColumns: "repeat(5, 1fr)", justifyContent: 'center'}}>
                    <EmailShareButton url={url} subject={title}>
                    <EmailIcon size={44} round={true}/>
                </EmailShareButton>
            
            
                <FacebookShareButton url={url} title={title}>
                    <FacebookIcon size={44} round={true}/>
                </FacebookShareButton>
            
            
                <TwitterShareButton url={url} title={title} hashtags={['web3', 'web3Music', 'XRPL', 'SonarMuse', 'Streaming']}>
                    <XIcon size={44} round={true}/>
                </TwitterShareButton>
          
         
            
                <InstapaperShareButton url={url} title={title}>
                    <InstapaperIcon size={44} round={true}/>
                </InstapaperShareButton>
            
            
                <LinkedinShareButton url={url} summary={title} source='Sonar Muse'>
                    <LinkedinIcon size={44} round={true}/>
                </LinkedinShareButton>
          
          
                <PinterestShareButton url={url} description={title} media={media}>
                    <PinterestIcon size={44} round={true}/>
                </PinterestShareButton>
          
          
                <RedditShareButton url={url} title={title}>
                    <RedditIcon size={44} round={true}/>
                </RedditShareButton>
           
                <TumblrShareButton url={url} title={title}>
                    <TumblrIcon size={44} round={true}/>
                </TumblrShareButton>
            
                <TelegramShareButton url={url} title={title}>
                    <TelegramIcon size={44} round={true}/>
                </TelegramShareButton>
            
                <WhatsappShareButton url={url} title={title}>
                    <WhatsappIcon size={44} round={true}/>
                </WhatsappShareButton>
            </FormWrapper>
            <ShareButtonClipboardWrapper>
                <ShareButtonClipboardText>{url}</ShareButtonClipboardText>
                <ShareButtonClipboardButton onClick={() => {
                     copyTextToClipboard(url).then(() => {
                        addAlert({message: `Copied to clipboard`, type: ALERT_TYPES.INFO, secs: 15})
                        setCopied(true)
                      }).catch(err => {
                        addAlert({message: `Something went wrong. Try again`, type: ALERT_TYPES.DANGER})
  
                      })
                }}>{isCopied ? 'Copied' : 'Copy'}</ShareButtonClipboardButton>
            </ShareButtonClipboardWrapper>
                
            
        </OptionModal>
    </>
  )
}

export default ShareButton